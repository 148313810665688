body {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  direction: rtl;
  box-sizing: border-box;
  overflow-x: hidden;
  border-style: solid;
}

/* this is for get safe-area-inset for all agents */
/* :root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); 
  --sal: env(safe-area-inset-left);
} */

.navigationBar {
  padding: calc(env(safe-area-inset-bottom) - 1.5rem) 16px
    calc(env(safe-area-inset-bottom) - 0.5rem) 16px;
}

.navigationBarIos {
  padding: calc(34px - 1.5rem) 16px calc(34px - 0.5rem) 16px;
}

input,
textarea {
  outline: none;
  display: block;
}
img {
  display: block;
}

* {
  -webkit-tap-highlight-color: transparent;
}

span.no-breaks br {
  display: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 2px;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.slick-prev::before,
.slick-next::before {
  content: "" !important;
}
.slick-initialized {
  overflow: hidden !important;
}
.home-carousel .slick-slide {
  transform: scale(0.95);
  transform-origin: bottom;
  /* width: 320px !important; */
}

.slick-slide img {
  display: inline!important;
}


.slick-slide {
  transform-origin: bottom;
}
.slick-center {
  transform: scale(1) !important;
}
.slider_dots {
  display: flex !important;
  justify-content: center;
  gap: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.slider_dots.product-cover {
  padding-top: 16px;
  padding-bottom: 16px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.slider_dots li {
  display: flex;
  justify-content: center;
  background-color: #dadde3;
  border-radius: 100%;
  cursor: pointer;
}
.slider_dots.product-cover li {
  background-color: #c0c6d0;
}
.slider_dots li.slick-active {
  background-color: #868ea3;
}
.slider_dots.product-cover li.slick-active {
  background-color: #1577e6;
}
.slider_dots li button {
  display: flex;
  justify-content: center;
  font-size: 0;
  width: 10px;
  height: 10px;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.lg-container {
  direction: ltr !important;
}

.gallery-item:focus-visible {
  outline: none;
  border: none;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  box-shadow: 0 0 10px #85888c;
  padding: 50px;
  padding-top: 125px;
  background-color: #f5f6fa;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuToggle input:checked ~ ul {
  transform: none;
}

.ReactCollapse--collapse {
  transition: height 0ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0; /* gutter size offset */
  width: auto;
  gap: 8px;
  padding: 16px;
}
.masonry-grid_column {
  padding-left: 0; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 8px;
}

.shadow-plus {
  filter: drop-shadow(0px 18px 7px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}

/* checkout style  */

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(0,165,178)' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.no-appearance:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

/* .continual {
    background-image: url("../assets/images/banner.jpeg");
    background-position: 50% center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .prepaid {
    background-image: url("../assets/images/banner2.jpeg");
    background-position: 50% center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .vip {
    background-image: url("../assets/images/banner3.jpeg");
    background-position: 50% center;
    background-size: cover;
    background-repeat: no-repeat;
  } */

.move-animation {
  animation: linear infinite;
  animation-name: move;
  animation-duration: 3s;
  animation-delay: 250ms;
}

.heart-animation {
  animation: linear infinite;
  animation-name: heartBeat;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2ecc71, 0 0 5px #2ecc71;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #2ecc71;
  border-left-color: #2ecc71;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

.btn {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
}

.btn.btn-border-3::after,
.btn.btn-border-3::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  transition: 0.5s;
}

.btn.btn-border-3::after {
  top: -9px;
  left: -9px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
}

.btn.btn-border-3::before {
  bottom: -9px;
  right: -9px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
}

.btn.btn-border-3:hover {
  color: var(--primary-main, #de4437);
}

.btn.btn-border-3:hover::after,
.btn.btn-border-3:hover::before {
  width: 30px;
  height: 30px;
  border-color: var(--primary-main, #de4437);
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ReactCollapse--collapse {
  transition: height 700ms;
}

/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 8px);
  color: var(--primary-main, #de4437);
  font-size: 12px;
}

.input-container .filled {
  transform: translate(0, 8px);
  font-size: 12px;
}
.input-container .filled ~ input {
  padding-top: 20px;
}
.input-container .filled ~ textarea {
  padding-top: 20px;
}
.input-container > textarea:focus {
  padding-top: 20px;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 16px);
  transform-origin: top right;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  line-height: 1;
  right: 16px;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
  padding-top: 20px;
}

.css-t3ipsp-control {
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

:root {
  --rmdp-primary-green: #3d9970;
  --rmdp-secondary-green: #87ad92;
  --rmdp-shadow-green: #87ad92;
  --rmdp-today-green: #01ff70;
  --rmdp-hover-green: #2ecc40;
  --rmdp-deselect-green: #39795c;
}

.sepdatepicker .rmdp-wrapper {
  border: 1px solid var(--primary-main);
  box-shadow: 0 0 5px var(--primary-main);
}

.sepdatepicker .rmdp-panel-body li {
  background-color: var(--primary-main);
  box-shadow: 0 0 2px var(--primary-main);
}

.sepdatepicker .rmdp-week-day {
  color: var(--primary-main);
}

.sepdatepicker .rmdp-day.rmdp-deactive {
  color: var(--primary-main);
}

.sepdatepicker .rmdp-range {
  background-color: var(--primary-main);
  box-shadow: 0 0 3px var(--primary-main);
}

.sepdatepicker .rmdp-arrow {
  border: solid var(--primary-main);
  border-width: 0 2px 2px 0;
}

.sepdatepicker .rmdp-arrow-container:hover {
  background-color: var(--primary-main);
  box-shadow: 0 0 3px var(--primary-main);
}

.sepdatepicker .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--primary-main);
}

.sepdatepicker .rmdp-day.rmdp-today span {
  background-color: #b0b4b8;
}

.sepdatepicker .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--primary-main);
}

.sepdatepicker .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary-main);
  box-shadow: 0 0 3px var(--primary-main);
}

.sepdatepicker .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--primary-main) !important;
}

.sepdatepicker .b-deselect {
  color: var(--primary-main);
  background-color: white;
}

.sepdatepicker .rmdp-action-button {
  color: var(--primary-main);
}

.sepdatepicker .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--primary-main);
}

.sepdatepicker .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--primary-main);
}

.product-description h1,
.product-description h2,
.product-description h3,
.product-description h4,
.product-description h5,
.product-description h6 {
  color: var(--primary-main);
  font-weight: bold;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.05);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.05);
  }

  70% {
    transform: scale(1);
  }
}

@keyframes move {
  0% {
    left: 0;
    transform: translateX(0);
  }
  50% {
    left: 0;
    transform: translateX(15px);
  }
  100% {
    left: 0;
    transform: translateX(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


.pageBuilderColorPicker .react-colorful{
  width: calc(100% - 1rem );
}
