@tailwind base;
@tailwind components;
@tailwind utilities;

@import "viewerjs-react/dist/index.css";

@layer base {
  select[dir="rtl"] {
    background-position: left theme("spacing.2") center;
    padding-left: theme("spacing.14");
    padding-right: theme("spacing.3");
  }
  [type="text"]:focus {
    box-shadow: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  [type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e") !important;
  }
  a {
    color: #1577e6;
    cursor: pointer;
  }
  a:hover {
    color: #1765d4;
  }
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.static-page-style .container {
  margin: auto;
}

.lite-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
